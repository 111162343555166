import React from 'react'
import OrangeCheckboxIcon from '../../assets/img/orange-checkbox-icon.svg'
import YellowCheckboxIcon from '../../assets/img/yellow-checkbox-icon.svg'
import PurpleCheckboxIcon from '../../assets/img/purple-checkbox-icon.svg'
import BlueCheckboxIcon from '../../assets/img/blue-checkbox-icon.svg'
import grayCheckboxIcon from '../../assets/img/gray-checkbox-icon.svg'
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from '../../components/loader';
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { apiUrl } from "../../utils/url";
import { useHistory } from 'react-router-dom';
import SideBar from '../side-bar';
import PageHeader from '../page-header';
import tickIcon from "../../assets/img/white-tick.svg";
import { audiencePosts as manageStripePost } from '../../services/postRequests/audience'
import lifetime from "../../assets/img/lifetime.svg"

const NewUpgradePage = () => {

    const [error, setError] = useState([])
    const history = useHistory()
    const { slug } = useSelector(state => state?.slugReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const pro = useSelector(state => state?.proReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const [monthlyPlan, setMonthlyPlan] = useState(true)
    const [yearlyPlan, setYearlyPlan] = useState(false)
    const [monthlyPlan2, setMonthlyPlan2] = useState(true)
    const [yearlyPlan2, setYearlyPlan2] = useState(false)
    const userData =  useSelector(state => state.userDataReducer)
    function togglePlans(e) {
        const selectedValue = e.target.value;
        if (selectedValue === 'yearly') {
            setYearlyPlan(true)
            setMonthlyPlan(false)
        } else if (selectedValue === 'monthly'){
            setMonthlyPlan(true)
            setYearlyPlan(false)
        }
    }
    function togglePlans2(e,value) {
        console.log("value",value);
        const selectedValue = e?.target?.value ?? value;
        if (selectedValue === 'yearly') {
            setYearlyPlan2(true)
            setMonthlyPlan2(false)
        } else if (selectedValue === 'monthly'){
            setMonthlyPlan2(true)
            setYearlyPlan2(false)
        }
    }
    const handleSubmit = (e,type) => {
        showLoader();
        setError([])
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)

        const formdata = new FormData();
        formdata.append('project',slug);
        formdata.append('type',type);
        formdata.append('plan_type',e.target.name);
        formdata.append("tolt_referral", window.tolt_referral??'');
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/subscribe-plan", requestOptions)
            .then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {

                        location.href = response?.data?.url;

                        localStorage.setItem("temp_slug",response?.data?.slug);
                        localStorage.setItem("last_activity", `/project/${response?.data?.slug}/settings`)
                        localStorage.setItem("plan_type",e.target.name)
                        localStorage.setItem("period",type)
                }
                else {
                    setError(getErrors(response?.errors))
                    toast.error(response?.status_message)
                }
            }).catch((err) => hideLoader())

           

    }

    const manageStripe = (e) => {
        showLoader()
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("project", slug)
        const api = apiUrl + `/manage-subscription`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        manageStripePost(api, hideLoader, setOptions).then((response) => {
            if (response) {
                location.href = response?.url
                showLoader()
            }
        })
    }
    return (
        // <div id="wrapper">
            <div className='up-cont-u'>
            
            {/* from="zaap-upgrade" */}
            {/* <div class="community-main"> */}
                {/* <SideBar from="zaap-upgrade"/> */}
                    <div className='choose-plan-container upgrade-card'>
                        <div className="plan-text-container">
                            <h1 className='settings-section-heading'>
                                Upgrade
                            </h1>
                            <div className='choose-plan-type'>
                            <div class="up-in-toggle bg-f1f1f1 schedule-toggle w-50 btn-semi-rounded" style={{ padding: "5px 3px" }}>
                                    <input type="radio" id="text_align_left" name="scheduleTime" onChange={togglePlans} checked={monthlyPlan} style={{ display: "none" }}  value={"monthly"} />
                                    <label for="text_align_left" class="btn-semi-rounded newsletter-btn-semi-rounded " > <span className='choose-plan-type-text'> Monthly</span></label>
                                    <input type="radio" id="text_align_right" name="scheduleTime" onChange={togglePlans} checked={yearlyPlan} style={{ display: "none" }} value={"yearly"}  />
                                    <label for="text_align_right" class="btn-semi-rounded newsletter-btn-semi-rounded "  > <span className='choose-plan-type-text'> Yearly</span></label>
                                </div>
                            </div>
                            <div class="plan-box">
                                <svg className='mobsvg' fill="none" viewBox="0 0 387 133" xmlns="http://www.w3.org/2000/svg"><path d="m0 126.9c97.123 0 275.14 8.118 382-122.9" stroke="url(#a)" stroke-width="11"/><defs><linearGradient id="a" x1="310.58" x2="61.054" y1="105.93" y2="112.1" gradientUnits="userSpaceOnUse"><stop stop-color="#FFBA34" offset="0"/><stop stop-color="#18f" offset=".49745"/><stop stop-color="#5F5F5F" offset="1"/></linearGradient></defs></svg>
                                <svg className='tabsvg' xmlns="http://www.w3.org/2000/svg" width="700" height="130" viewBox="0 0 700 130" fill="none"><path d="M0 124.353C179.5 124.353 508.5 132.5 706 1" stroke="url(#paint0_linear_2452_15450)" stroke-width="11"/><defs><linearGradient id="paint0_linear_2452_15450" x1="574.013" y1="103.3" x2="113.513" y2="124.289" gradientUnits="userSpaceOnUse"><stop stop-color="#FFBA34"/><stop offset="0.177083" stop-color="#F5DB85"/><stop offset="0.333333" stop-color="#A0D9DF"/><stop offset="0.497448" stop-color="#1188FF"/><stop offset="1" stop-color="#5F5F5F"/></linearGradient></defs></svg>
                                <div className='plancircle graycrcle'>
                                    {!pro && <img src={tickIcon} alt="" />}
                                </div>
                                <div className='plancircle bluecrcle'>
                                    {pro && planType != "ultra" && <img src={tickIcon} alt="" />}
                                </div>
                                <div className='plancircle yellowcrcle'>
                                    {pro && planType == "ultra" && <img src={tickIcon} alt="" />}
                                </div>
                            </div>
                            {/* {(pro != 1 && pro != true) &&
                            <div className=' mt-3 activate-profile'>
                                To Activate Your Profile, Join Any Plan
                            </div>
                            } */}
                        </div>
                        {monthlyPlan &&
                            <div className='plan-cards-container'  id="monthly-plans">
                                {/* free plan */}
                                {!userData?.remove_free &&
                                 <div className='plan-card grey-box-shadow'>
                                    <div>
                                        <div className='card-text-container'>
                                            <h3 className='plan-card-period cardhead-u mb-1'>Free</h3>
                                            <h1 className='mb-0 plan-card-price price-u'>$0/mo</h1>
                                        </div>
                                        <div className='features-main-container'>
                                            <div className='features-list-container'>
                                                <div className='plan-feature-list'>
                                                    <img src={grayCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Unlimited Links</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={grayCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Link Clicks Analytics</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={grayCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Custom Theme</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={grayCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Build Email List</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={grayCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Socials Icons</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='features-main-container'>
                                        <div className='plan-button-container'>
                                        {!pro ?
                                            <button className='plan-trial-button blue-button clr-gray'>Current Plan</button>
                                            :
                                            <button name="free" className='plan-trial-button blue-button clr-gray' onClick={manageStripe}>Downgrade to Free</button>
                                        }
                                        </div>
                                    </div>
                                </div>
                                }
                                {/* pro plan */}
                                <div className='plan-card blue-box-shadow'>
                                    <div>
                                        <div className='card-text-container'>
                                            <h3 className='plan-card-period cardhead-u mb-1'>Pro</h3>
                                            <h1 className='mb-0 plan-card-price price-u'>$4/mo</h1>
                                        </div>
                                            <div className='features-list-container'>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Custom Domain</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Email marketing</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Sell digital products</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Sell coaching calls</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Advanced analytics</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Sync-blocks</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Publish blog posts</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>+ Everything in free</p>
                                                </div>
                                            </div>
                                    </div>
                                    <div className='features-main-container'>
                                        <div className='plan-button-container'>
                                        {(pro == 1 || pro == true) && (planType == 'pro' || !planType) ? 
                                            <button className='plan-trial-button blue-button'>Current Plan</button>
                                            :
                                            <button onClick={(e) => handleSubmit(e,'monthly')} name="pro" className='plan-trial-button blue-button'>{pro == 1 ? 'Downgrade ':'Upgrade '} to Pro</button>
                                        }
                                        </div>
                                    </div>
                                </div>
                                {/* ultra plan */}
                                <div className='plan-card yellow-box-shadow'>
                                    <div>
                                        <div className='card-text-container'>
                                            <h3 className='plan-card-period cardhead-u mb-1'>Ultra</h3>
                                            <h1 className='mb-0 plan-card-price price-u'>$9/mo</h1>
                                        </div>

                                        <div className='features-main-container'>
                                            <div className='features-list-container'>
                                                <div className='plan-feature-list'>
                                                    <img src={YellowCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Remove Zaap Branding</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={YellowCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Invite Team Members</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={YellowCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Priority support</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={YellowCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>1-on-1 coaching</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={YellowCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Creator University</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={YellowCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>+ Everything in Pro</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='plan-button-container'>
                                    {(pro == 1 || pro == true) && (planType == 'ultra') ? 
                                            <button className='plan-trial-button yellow-button'>Current Plan</button>
                                            :
                                        <button onClick={(e) => handleSubmit(e,'monthly')} name="ultra" className='plan-trial-button yellow-button'>Upgrade to Ultra</button>
                                    }
                                    </div>
                                </div>
                            </div> 
                        }
                        {yearlyPlan &&
                        <div className='plan-cards-container' id="yearly-plans" >
                             {/* free plan */}
                             <div className='plan-card grey-box-shadow'>
                                    <div>
                                        <div className='card-text-container'>
                                            <h3 className='plan-card-period cardhead-u mb-1'>Free</h3>
                                            <h1 className='mb-0 plan-card-price price-u'>$0/mo</h1>
                                        </div>
                                        <div className='features-main-container'>
                                            <div className='features-list-container'>
                                                <div className='plan-feature-list'>
                                                    <img src={grayCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Unlimited Links</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={grayCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Link Clicks Analytics</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={grayCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Custom Theme</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={grayCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Build Email List</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={grayCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Socials Icons</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='features-main-container'>
                                        <div className='plan-button-container'>
                                        {!pro ? 
                                            <button className='plan-trial-button blue-button clr-gray'>Current Plan</button>
                                            :
                                            <button name="free" className='plan-trial-button blue-button clr-gray' onClick={manageStripe}>Downgrade to Free</button>
                                        }
                                        </div>
                                    </div>
                            </div>
                            {/* pro plan */}
                            <div className='plan-card blue-box-shadow'>
                                    <div>
                                        <div className='card-text-container'>
                                            <h3 className='plan-card-period cardhead-u mb-1'>Pro</h3>
                                            <h1 className='mb-0 plan-card-price price-u'>$39/yr</h1>
                                        </div>
                                        <div className='features-main-container'>
                                            <div className='features-list-container'>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Custom Domain</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Email marketing</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Sell digital products</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Sell coaching calls</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Advanced analytics</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Sync-blocks</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>Publish blog posts</p>
                                                </div>
                                                <div className='plan-feature-list'>
                                                    <img src={BlueCheckboxIcon} alt="" />
                                                    <p className='feature-list-text list-u'>+ Everything in free</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='features-main-container'>
                                        <div className='plan-button-container'>
                                        {(pro == 1 || pro == true) && (planType == 'pro' || !planType) ? 
                                            <button className='plan-trial-button blue-button'>Current Plan</button>
                                            :
                                            <button onClick={(e) => handleSubmit(e,'yearly')} name="pro" className='plan-trial-button blue-button'>{pro == 1 ? 'Downgrade ':'Upgrade '} to Pro</button>
                                        }
                                        </div>
                                    </div>
                            </div>
                            {/* ultra plan */}
                            <div className='plan-card yellow-box-shadow'>
                                <div>
                                    <div className='card-text-container'>
                                        <h3 className='plan-card-period cardhead-u mb-1'>Ultra</h3>
                                        <h1 className='mb-0 plan-card-price price-u'>$89/yr</h1>
                                    </div>

                                    <div className='features-main-container'>
                                        <div className='features-list-container'>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Remove Zaap Branding</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Invite Team Members</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Priority support</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>1-on-1 coaching</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Creator University</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>+ Everything in Pro</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='plan-button-container'>
                                {(pro == 1 || pro == true) && (planType == 'ultra') ? 
                                        <button className='plan-trial-button yellow-button'>Current Plan</button>
                                        :
                                    <button onClick={(e) => handleSubmit(e,'yearly')} name="ultra" className='plan-trial-button yellow-button'>Upgrade to Ultra</button>
                                }
                                </div>
                            </div>

                            {/* <div className='plan-card'>
                                <div>

                                    <div className='card-text-container'>
                                        <h3 className='plan-card-period'>Pro</h3>
                                        <h1 className='mb-0 plan-card-price text-blue'>$79/yr</h1>
                                    </div>
                                    <div className='features-main-container'>
                                        <div className='features-list-container'>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Sell Coaching Calls</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Sell Digital Products</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Downloads & Lead Magnets</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Use A Custom Domain</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Detail Analytics</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Blog Posts & Newsletters</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Email List Builder</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='features-main-container'>
                                    <div className='plan-button-container'>
                                    {(pro == 1 || pro == true) && (planType == 'pro' || !planType) ? 
                                        <button className='plan-trial-button blue-button'>Current Plan</button>
                                        :
                                        <button onClick={(e) => handleSubmit(e,'yearly')} name="pro" className='plan-trial-button blue-button'>{pro == 1 && planType == "ultra" ? "Downgrade " :"Upgrade "} to Pro</button>
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className='plan-card'>
                                <div>
                                    <div className='card-text-container'>
                                        <h3 className='plan-card-period'>Ultra</h3>
                                        <h1 className='mb-0 plan-card-price text-yellow'>$279/yr</h1>
                                    </div>

                                    <div className='features-main-container'>
                                        <div className='features-list-container'>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>0% Selling fee</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Remove Zaap Branding</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>+ Everything in Pro</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='plan-button-container'>
                                {(pro == 1 || pro == true) && (planType == 'ultra') ? 
                                        <button className='plan-trial-button yellow-button'>Current Plan</button>
                                        :
                                    <button onClick={(e) => handleSubmit(e,'yearly')} name="ultra" className='plan-trial-button yellow-button'>Upgrade to Ultra</button>
                                }
                                </div>
                            </div> */}
                        </div>
                        }

                       
                    </div>
                    <div className='choose-plan-container upgrade-card big-cont'>
                        {/* pro plane panel */}
                        <div className="plan-text-container nowrap">
                            <h2 className='settings-section-heading mb-0'>
                                Purchase Lifetime
                            </h2>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-between flex-column '>
                                    <div className="plan-text-container ps-0 pt-1">
                                        <div className='adv-plan-card lifetime-adv-plan-card'>
                                            <div className='free-u'>
                                                <img src={lifetime} alt="" />
                                            </div>
                                            <div className='free-head'>
                                                <h3 className='head-free-u'>
                                                    One payment. Access forever.
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='adv-plan-card lifetime-adv-plan-card'>
                                            <div className='free-u'>
                                                <img src={lifetime} alt="" />
                                            </div>
                                            <div className='free-head'>
                                                <h3 className='head-free-u'>
                                                    Unlock Every Feature From Pro + Ultra.
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='adv-plan-card lifetime-adv-plan-card'>
                                            <div className='free-u'>
                                                <img src={lifetime} alt="" />
                                            </div>
                                            <div className='free-head'>
                                                <h3 className='head-free-u'>
                                                    Enjoy Future Updates.
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='adv-plan-card lifetime-adv-plan-card'>
                                            <div className='free-u'>
                                                <img src={lifetime} alt="" />
                                            </div>
                                            <div className='free-head'>
                                                <h3 className='head-free-u'>
                                                    No Subscription.
                                                </h3>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-wrap lifetime-terms'>
                                        *Lifetime terms and conditions apply. Email broadcast sending has a ‘fair-use’ policy. We reserve the right to block email sending if emails break the conditions (Such as spam sending) or if email sending volume is outside the fair use number.
                                    </div>
                                </div>
                                <div className='lifetime-plan-card purple-box-shadow'>
                                    <div>
                                        <div className='card-text-container'>
                                            <h3 className='plan-card-period cardhead-u mb-1'>Lifetime</h3>
                                            <h1 className='mb-0 plan-card-price price-u'>$249/one-time</h1>
                                        </div>

                                        <div className='features-main-container'>
                                            <div className='features-list-container'>
                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Sell Products + Meetings</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>YouTube/TikTok Sync</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Create Lead Magnets</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>3rd Party Integrations</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Detailed Analytics</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Publish Blog Posts</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Invite Team Members</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Use A Custom Domain</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Send Email Broadcasts</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Remove Zaap Branding</p>
                                            </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='plan-button-container'>
                                    {(pro == 1 || pro == true) && (planType == 'lifetime') ? 
                                            <button className='plan-trial-button purple-button'>Current Plan</button>
                                            :
                                        <button onClick={(e) => handleSubmit(e,'lifetime')} name="lifetime" className='plan-trial-button purple-button'>Upgrade to Lifetime</button>
                                    }
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>
                    <div className='choose-plan-container upgrade-card big-cont'>
                        {/* pro plane panel */}
                        <div className="plan-text-container nowrap">
                                <h2 className='settings-section-heading'>
                                    Calculate Savings
                                </h2>
                                <div className='choose-plan-type'>
                                <div class="up-in-toggle bg-f1f1f1 schedule-toggle w-50 btn-semi-rounded" style={{ padding: "5px 3px" }}>
                                        <input type="radio" id="text_align_left2" name="selectPlan" onChange={togglePlans2} checked={monthlyPlan2} style={{ display: "none" }}  value={"monthly"} />
                                        <label for="text_align_left2" class="btn-semi-rounded newsletter-btn-semi-rounded " > <span className='choose-plan-type-text'> Pro Plan</span></label>
                                        <input type="radio" id="text_align_right2" name="selectPlan" onChange={togglePlans2} checked={yearlyPlan2} style={{ display: "none" }} value={"yearly"}  />
                                        <label for="text_align_right2" class="btn-semi-rounded newsletter-btn-semi-rounded "  > <span className='choose-plan-type-text'> Ultra Plan</span></label>
                                    </div>
                                </div>
                                {/* {(pro != 1 && pro != true) &&
                                <div className=' mt-3 activate-profile'>
                                    To Activate Your Profile, Join Any Plan
                                </div>
                                } */}
                        </div>
                        {/* {monthlyPlan2 &&
                            <div className='plan-text-container'>
                                <div className='range-box'>
                                    <div className='ranepop'><svg xmlns="http://www.w3.org/2000/svg" width="133" height="59" viewBox="0 0 133 59" fill="none"><rect width="133" height="47" rx="23.5" fill="#0F615C"/><path d="M67.2103 56.1788C66.4317 57.463 64.5683 57.463 63.7897 56.1788L58.2477 47.0368C57.4397 45.7039 58.3993 44 59.958 44L71.042 44C72.6007 44 73.5603 45.7039 72.7523 47.0368L67.2103 56.1788Z" fill="#0F615C"/></svg><span>$71/mo<br/>in savings</span></div>
                                    <div className='rangecircle'></div>
                                    <div className='range-line'></div>
                                </div>  
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag clr-gray'>
                                            Free
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Advanced Link-in-bio  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Linktree, Webflow, Carrd
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag clr-gray'>
                                            Free
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Email List Builder 
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces ConvertKit, Mailchimp, BeeHiiv
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag blue-button'>
                                            Pro
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Advanced Analytics  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Linktree, Webflow, Carrd
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag blue-button'>
                                            Pro
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Send Email Broadcasts  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces ConvertKit, Mailchimp, BeeHiiv
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $50/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='price-area'>
                                    Total Monthly Price = <span className='disdate'>$80/mo <svg width="93" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.426 12.08c0-2.345 2.814-5.11 5.2-5.15 1.308-.022 2.58.057 3.881.174.708.064 1.995-.195 2.563.373.913.913.894 1.951 1.68 2.911 1.456 1.781 4.605.554 6.257-.547.54-.36 1.667-1.052 1.89-1.667.398-1.09 1.215-2.27 2.215-2.936 2.506-1.67 6.951-2.606 8.634.572 1.306 2.468.726 4.03 4.068 4.03 2.417 0 4.14-1.588 5.312-3.582.25-.425 1.1-.883 1.518-1.12.687-.39 1.019-1.039 1.766-1.343.879-.358 4.212-1.26 4.951-.336.633.79 2.097.93 2.576 1.791.3.541.46 2.004.895 2.352.535.428.696 1.206 1.381 1.58 1.253.683 3.12 1.02 4.38.211 1.13-.727 1.973-1.409 2.81-2.463.96-1.209 1.842-1.934 3.036-2.911 1.141-.934 2.113-1.972 3.732-2.016 1.371-.037 3.187.214 4.043 1.356.65.867 1.006 1.244 1.431 2.215a7.63 7.63 0 0 0 1.02 1.692c.627.783 2.96 1.231 3.894 1.231 1.001 0 2.09-.902 2.152-1.916.07-1.167 1.406-3.029 2.463-3.557.922-.461 4.131-1.268 4.703-.125" stroke="#FF1451" stroke-width="3" stroke-linecap="round"/></svg></span><span className='newprice'>$9/mo</span>
                                </div>
                            </div>
                        } */}
                        {/* ultra plane panel */}
                        {/* {yearlyPlan2 && */}
                            <div className="plan-text-container">
                                <div className='range-box'>
                                    <div className={`ranepop ${!monthlyPlan2 && 'changed-opacity'}`} onClick={(e)=>{togglePlans2(e,'monthly')}} ><svg xmlns="http://www.w3.org/2000/svg" width="133" height="59" viewBox="0 0 133 59" fill="none"><rect width="133" height="47" rx="23.5" fill="#0F615C"/><path d="M67.2103 56.1788C66.4317 57.463 64.5683 57.463 63.7897 56.1788L58.2477 47.0368C57.4397 45.7039 58.3993 44 59.958 44L71.042 44C72.6007 44 73.5603 45.7039 72.7523 47.0368L67.2103 56.1788Z" fill="#0F615C"/></svg><span>$71/mo<br/>in savings</span></div>
                                    <div className={`rangecircle ${!monthlyPlan2 && 'changed-opacity'}`} onClick={(e)=>{togglePlans2(e,'monthly')}} ></div>
                                    <div className={`ranepop popup2 ${monthlyPlan2 && 'changed-opacity'}`} onClick={(e)=>{togglePlans2(e,'yearly')}} ><svg xmlns="http://www.w3.org/2000/svg" width="133" height="59" viewBox="0 0 133 59" fill="none"><rect width="133" height="47" rx="23.5" fill="#0F615C"/><path d="M67.2103 56.1788C66.4317 57.463 64.5683 57.463 63.7897 56.1788L58.2477 47.0368C57.4397 45.7039 58.3993 44 59.958 44L71.042 44C72.6007 44 73.5603 45.7039 72.7523 47.0368L67.2103 56.1788Z" fill="#0F615C"/></svg><span>$126/mo<br/>in savings</span></div>
                                    <div className={`rangecircle2 ${monthlyPlan2 && 'changed-opacity'}`} onClick={(e)=>{togglePlans2(e,'yearly')}} ></div>
                                    <div className='range-line'></div>
                                </div>  
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag clr-gray'>
                                            Free
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Advanced Link-in-bio  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Linktree, Webflow, Carrd
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag clr-gray'>
                                            Free
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Email List Builder 
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces ConvertKit, Mailchimp, BeeHiiv
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag blue-button'>
                                            Pro
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Advanced Analytics  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Linktree, Webflow, Carrd
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag blue-button'>
                                            Pro
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Send Email Broadcasts  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces ConvertKit, Mailchimp, BeeHiiv
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $50/mo
                                        </div>
                                    </div>
                                </div>
                                <div className={`adv-plan-card ${monthlyPlan2 && 'changed-opacity'}`}>
                                    <div className='free-u'>
                                        <div className='free-tag yellow-button'>
                                            Ultra
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Sell Digital Products 
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Gumroad, PayHip, Shopify
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $50/mo
                                        </div>
                                    </div>
                                </div>
                                <div className={`adv-plan-card ${monthlyPlan2 && 'changed-opacity'}`}>
                                    <div className='free-u'>
                                        <div className='free-tag yellow-button'>
                                            Ultra
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Calendar Bookings  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Calendly
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='price-area'>
                                    Total Monthly Price = <span className='disdate'>{monthlyPlan2 ? '$80/mo' : '$145/mo'} <svg width="93" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.426 12.08c0-2.345 2.814-5.11 5.2-5.15 1.308-.022 2.58.057 3.881.174.708.064 1.995-.195 2.563.373.913.913.894 1.951 1.68 2.911 1.456 1.781 4.605.554 6.257-.547.54-.36 1.667-1.052 1.89-1.667.398-1.09 1.215-2.27 2.215-2.936 2.506-1.67 6.951-2.606 8.634.572 1.306 2.468.726 4.03 4.068 4.03 2.417 0 4.14-1.588 5.312-3.582.25-.425 1.1-.883 1.518-1.12.687-.39 1.019-1.039 1.766-1.343.879-.358 4.212-1.26 4.951-.336.633.79 2.097.93 2.576 1.791.3.541.46 2.004.895 2.352.535.428.696 1.206 1.381 1.58 1.253.683 3.12 1.02 4.38.211 1.13-.727 1.973-1.409 2.81-2.463.96-1.209 1.842-1.934 3.036-2.911 1.141-.934 2.113-1.972 3.732-2.016 1.371-.037 3.187.214 4.043 1.356.65.867 1.006 1.244 1.431 2.215a7.63 7.63 0 0 0 1.02 1.692c.627.783 2.96 1.231 3.894 1.231 1.001 0 2.09-.902 2.152-1.916.07-1.167 1.406-3.029 2.463-3.557.922-.461 4.131-1.268 4.703-.125" stroke="#FF1451" stroke-width="3" stroke-linecap="round"/></svg></span><span className='newprice'>{monthlyPlan2 ? '$9/mo' : '$19/mo'}</span>
                                </div>
                            </div>
                        {/* } */}
                    </div>
                   
            {/* </div> */}
            </div>
        // </div>
            //  <div class="page-aside-bg5"></div> 
        
    )
}
export default NewUpgradePage;
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import thundaricon from "../../assets/img/products/thunder-icon.svg"
import upgradeplanimage from "../../assets/img/upgrade-plan-image.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import BlueCheckboxIcon from '../../assets/img/blue-checkbox-icon.svg'
import pro from '../../assets/img/pro-image.png'
import ultra from '../../assets/img/ultra-image.png'
import { toast } from "react-toastify";
import { hideLoader, showLoader } from "../loader";
import { apiUrl } from "../../utils/url";


export default function Teammoadal({from,showModal,setShowModal,setActivePage, heading, text, isUltra}) {
    const history = useHistory();
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const plan = isUltra ? 'ultra' : 'pro';
    const upgradeHandleClick = () => {
        if (!from || (from && !from?.includes("settings"))) {
            history.push(basePath + "/settings");
        }else{
            setShowModal(false)
            return setActivePage("upgrade");
        }
        
    };
    const hideModal = () => {
        setShowModal(false)
        // history.push(basePath + "/page-editor/customizePageModal/"+from)
    };
    const handleSubmit = (e) => {
        showLoader();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData();
        formdata.append('project',slug);
        formdata.append('type','monthly');
        formdata.append('plan_type',plan);
        formdata.append("tolt_referral", window.tolt_referral??'');
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/subscribe-plan", requestOptions)
            .then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {

                        location.href = response?.data?.url;

                        localStorage.setItem("temp_slug",response?.data?.slug);
                        localStorage.setItem("last_activity", `/project/${response?.data?.slug}/page-editor`)
                        localStorage.setItem("plan_type",plan)
                        localStorage.setItem("period",'monthly')
                }
                else {
                    toast.error(response?.status_message)
                }
            }).catch((err) => hideLoader())

           

    }
    return (
        <div class="upgradeTeamModal">
            <div id="upgradeTeamModal">
                <div className="" role="document">  
                    <div className="modal-content modal-semi-curve h-auto" style={{ boxShadow: "0px 0px 29px rgba(48, 49, 62, 0.12)", border: 'none' }}>
                        <div className="modal-header">
                            <div className="fs-14px font-weight-500">
                                Upgrade to {isUltra ? 'Ultra' : 'Pro'} to use this feature
                            </div>
                            {from && from.includes('customize') &&
                            <button onClick={hideModal} type="button" class="close-new">
                                <span aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <path d="M9 1L1 9M1 1L9 9" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Close
                                </span>   
                            </button>
                            }
                        </div>
                        <div className="modal-body modal-u">
                            <div>
                                <img src={plan == 'ultra' ? ultra : pro }/>
                            </div>
                            <form name="create_folder" className="create_folder" method="post" role="form" >
                                {/* <div className="notification-container"></div> */}


                                <div className="form-group">
                                {/* <span className={`project-types ${isUltra ? "ultra" : 'pro'}`}>
                                    {isUltra ? 'Ultra' : 'Pro'}
                                </span> */}
                                    <div>
                                        <span className="font-weight-500" style={{fontSize:'22px'}}>Level up with {isUltra ? 'Ultra' : 'Pro'}. </span>
                                        <p className="text-gray font-weight-500" style={{fontSize:"14px"}}>
                                        Unlock a whole lot of power with {isUltra ? 'Ultra' : 'Pro'}. Only ${isUltra ? '9' : '4'}/mo.
                                        </p>
                                        <div className="d-flex flex-column gap-2 fs-14px">
                                            <div className="d-flex align-items-center gap-2">
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <span>Custom Theme</span>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <span>Contact Form</span>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <span>Auto-Sync Widgets</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button
                                        type="button"
                                        onClick={handleSubmit}
                                        className="btn mt-3 fs-12px mb-2 px-3 btn-primary btn-semi-rounded"
                                    >
                                        Join {isUltra ? 'Ultra' : 'Pro'}
                                    </button>
                                    <button onClick={upgradeHandleClick} className="btn fs-12px mt-3 mb-2 px-3 ms-2 btn-grey btn-semi-rounded" >
                                        View All Plans
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import stripeIcon from "../assets/img/checklist/stripe-checklist-img.svg"
import productIcon from "../assets/img/checklist/product-checklist-img.svg"
import socialIcon from "../assets/img/checklist/social-checklist-img.svg"
import upgradeProIcon from "../assets/img/checklist/upgrade-pro-checklist-img.svg"
import upgradeUltraIcon from "../assets/img/checklist/upgrade-ultra-checklist-img.svg"
import emailIcon from "../assets/img/checklist/email-checklist-img.svg"
import postIcon from "../assets/img/checklist/post-checklist-img.svg"
import teamIcon from "../assets/img/checklist/team-checklist-img.svg"
import domainIcon from "../assets/img/checklist/domain-checklist-img.svg"
import linkIcon from "../assets/img/checklist/link-checklist-img.svg"
import syncIcon from "../assets/img/checklist/sync-checklist-img.svg"
import { useSelector } from 'react-redux'
import { apiUrl } from '../utils/url'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Checklist = () => {
    const iconMapping = {
        link: linkIcon, 
        social: socialIcon, 
        upgrade_pro: upgradeProIcon, 
        link_account: stripeIcon,
        product: productIcon,
        sync: syncIcon, 
        email: emailIcon, 
        post: postIcon, 
        upgrade_ultra: upgradeUltraIcon, 
        team: teamIcon,
        domain: domainIcon, 
    };
    const history = useHistory();
    const pro = useSelector(state => state?.proReducer);
    const { slug } = useSelector(state => state?.slugReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const [checklist, setChecklist] = useState([]);
    useEffect(()=>{
        if (localStorage?.slug) {   
            getChecklist();
        }
    },[localStorage?.slug]);
    const getChecklist = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/checklist/get/${localStorage?.slug}`, {
            headers: myHeaders
        })
        const response = await res.json();
        if (response?.status_code == 200) {
            setChecklist(response?.data);
        }
        return;
    }

    const handleIgnore = async (id) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/checklist/ignore/${localStorage?.slug}/${id}`, {
            headers: myHeaders
        })
        const response = await res.json();
        if (response?.status_code == 200) {
            if (id == 'all') {
                setChecklist([]);
            }else{
                const newList = checklist.filter(item => item.id !== id);
                setChecklist(newList);
            }
        }
        return;
    };
    const redirectLink = (type,link) => {
       const path = '/project/' + localStorage?.slug + link;
        if (type == 'domain') {
            history.push({pathname: path,
                state: {
                    defaultPage: 'domains'
                }
            })
        }else if (type == 'team') {
            history.push({pathname: path,
                state: {
                    defaultPage: 'team'
                }
            })
        }else if (type == 'link_account') {
            history.push({pathname: path,
                state: {
                    defaultPage: 'payouts'
                }
            })
        }
        else{
            history.push(path)
        }
    };
    return (
        checklist?.length > 0 ? (
        <>
            <div className="border-grey"></div>
            <div className="checklist-container">
                <div className="d-flex justify-content-between align-items-center">
                    <div>Launch Checklist</div>
                    <div onClick={()=>handleIgnore('all')} className="box-gray cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M11 1L1 11M1 1L11 11" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
                <div className='checklist-main'>
                    {
                        checklist.map((val,index) => (
                            <div className="checklist-card" key={index}>
                                <div>
                                    <div className="title">{val?.title}</div>
                                    <div className="description">{val?.description}</div>
                                </div>
                                <div>
                                    <img width={"100%"} src={iconMapping[val?.type]} alt="" />
                                </div>
                                <div className="checklist-footer">
                                    <div className="number">{index + 1} of {checklist?.length}</div>
                                    <div className="d-flex align-items-center gap-1">
                                        <div onClick={() => handleIgnore(val?.id)} className="grey-btn">
                                            Ignore
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                                                <path d="M7.3335 1L1.3335 7M1.3335 1L7.3335 7" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <button onClick={() => redirectLink(val?.type,val?.button_link)} className="blue-btn">
                                            {val?.button_text}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                                                <path d="M1.3335 7L7.3335 1M7.3335 1H3.3335M7.3335 1V5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
        ):null

    )
}

export default Checklist
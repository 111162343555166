import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { apiUrl } from '../../utils/url';
import DashboardLayout from './dashboard-layout';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { hideLoader, showLoader } from '../loader';
import Teammoadal from '../teammember/team_modal';
import Settings from '../settings/settings';
import NewDashboard from '../../pages/new-dashboard';
import StripeConnectReauth from '../../pages/stripe-connect-reauth';
import CallbackLogin from '../../pages/callback-login';
import AccountSettings from '../settings/account-settings';
import PaypalConnect from '../../pages/paypal-connect';
import StripeCheckout from '../../pages/stripe-checkout';
import StripeConnectReturn from '../../pages/stripe-connect-return';
import { AcceptInvite } from '../../pages/accept-invite';
import TwitterCallback from '../../pages/twitter-callback';
import TikTokCalBack from '../../pages/tiktok-callback';
import SpotifyCallBack from '../../pages/spotify-callback';
import InstagramCallBack from '../../pages/instagram-callback';
import ZoomCallback from '../../pages/zoom-callback';
import Logout from '../../pages/logout';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Login from '../../pages/login';
import { toast } from 'react-toastify';

const UnPaidPage = () => {
    const location = useLocation();
    const path = location.pathname;
    const { slug } = useSelector(state => state?.slugReducer);
    const [projectSlug, setProjectSlug] = useState(slug ?? localStorage?.slug);
    const dispatch = useDispatch();
    const history = useHistory();
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const projectDisabledReducer = useSelector(state => state?.projectDisabledReducer);
    const [otherRoutes, setOtherRoutes] = useState(
        <>
            <Route path={"/login"} component={() => <Login />} />
            <Route path={"/dashboard"} exact component={() => <NewDashboard onlyUpgrade={true}/>} />
            <Route path="/stripe-connect-reauth" component={() => <StripeConnectReauth />} />
            <Route path={"/callback-login"} component={() => <CallbackLogin />} />
            <Route path={"/project/:id/:pagename"} children={<SlugComponents showUpgradeModal={showUpgradeModal} setShowUpgradeModal={setShowUpgradeModal}/>} />
            <Route path={"/account-settings"} children={<AccountSettings />} />
            <Route path="/paypal-connect" component={() => <PaypalConnect slug={slug} />} />
            <Route path="/stripe-checkout" component={() => <StripeCheckout />} />
            <Route path="/accept-invite" component={() => <AcceptInvite />} />
            <Route path="/stripe-connect-return" component={() => <StripeConnectReturn />} />
            <Route path={"/twitter-callback"} component={() => <TwitterCallback />} />
            <Route path={"/tiktok-callback"} component={() => <TikTokCalBack />} />
            <Route path={"/spotify-callback"} component={() => <SpotifyCallBack />} />
            <Route path={"/instagram-callback"} component={() => <InstagramCallBack />} />
            <Route path={"/zoom-callback"} component={() => <ZoomCallback />} />
            <Route path={"/logout"} component={() => <Logout />} />

        </>
    );
    
    const upgradeHandleClick = (e) => {
        e.preventDefault();
        history.push("/project/" + id + "/settings");
    }

    const [screen, setScreen] = useState(
        <>
        <Route path={"/login"} component={() => <Login />} />
        <Route path={"/logout"} component={() => <Logout />} />
        </>
    );

    useEffect(() => {
        const path = location.pathname;

        const parts = path.split('/');
        const projectIndex = parts.indexOf('project');
        if (projectIndex !== -1 && parts[projectIndex + 1]) {
            setProjectSlug(parts[projectIndex + 1]);
        }
    }, [location.pathname]);

    // showLoader();

    useEffect(() => {
        if (projectSlug) {
            checkPlan(projectSlug);
        } else {
            setScreen(otherRoutes);
        }
    }, []);

    const checkPlan = async (slug) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/check-eligibilty/${slug}`, {
            headers: myHeaders
        });
        const response = await res.json();
        if (response?.status_code == 200) {
            hideLoader();
            if (response.data?.eligible) {
                setScreen(<DashboardLayout />);
            } else {
                dispatch({ type: 'SET_PROJECT_DISABLED', payload: true });
                dispatch({ type: "SET_SLUG", payload: { slug: projectSlug, basePath: "/project/" + projectSlug, newChange: false } });

                // Show upgrade modal and banner only if not on settings page
                const isSettingsPage = location.pathname.includes('/settings');
                setShowUpgradeModal(!isSettingsPage);
                setScreen(
                    <>
                    {!isSettingsPage && <section className={`demo-banner ${projectDisabledReducer}`}>
                        {showUpgradeModal && <Teammoadal showModal={showUpgradeModal} upgradeHandleClick={upgradeHandleClick} heading={'Upgrade to use Zaap'} text={'Choose a plan to continue using Zaap'} isUltra={false} />}
                    </section>}
                    {otherRoutes}
                    </>
                );
            }
        }else{
            hideLoader();
            toast.error(response?.status_message);
            if (response?.logout) {
                history.push("/logout");
            }
            return response;
        }
    };

    return (
        screen
    );
}

export const SlugComponents = ({showUpgradeModal, setShowUpgradeModal}) => {
    const { id, pagename } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        localStorage.setItem("slug", id);
        dispatch({ type: "SET_SLUG", payload: { slug: id, basePath: "/project/" + id, newChange: false } });
        setShowUpgradeModal(false);
    }, [id]);
    hideLoader();
    // Do not render Settings page if it's the settings page
    if (pagename === 'settings') {
        return <Route path={"/project/" + id + "/settings/"} children={<Settings onlyUpgrade={true} />} />;
    }else{
        return <Redirect to="/dashboard" />
    }

    return null;
};

export default UnPaidPage;

const INIT_STATE = {};
const userDataReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_USER_DATA':
            return action.payload
        case 'RESET_USER_DATA':
            return INIT_STATE
        default:
            return state
    }
}
export default userDataReducer
import returnIcon from "../../assets/img/modals/return.svg"
// import removeicon from "../../assets/img/products/removeicon.svg";
import * as $ from "jquery";
import { useHistory, useParams } from "react-router-dom";
import Loader, { hideLoader, showLoader } from "../loader";
import { Modal, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../utils/url";
import { toast } from "react-toastify";
import ErrorMessage from "../errors/errorMessage";
import { useState, useEffect, useRef } from "react";
import Teammoadal from "../teammember/team_modal";


const CodesModal = ({ from, show, setShow, callAllCodes, edit, setIsEdit, duplicate, setIsDuplicate,allProducts, setAllProducts }) => {
    let history = useHistory();
    const codesModalReducer = useSelector(state => state.codeModalReducer);
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [error, setError] = useState([])
    const dispatch = useDispatch();
    const [isEdit, setisEdit] = useState([]);
    const [showModalasd, setShowModalasd] = useState(false)
    const isPro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const isEligible = useSelector(state => state?.eligibilityReducer);
    const [checkedProducts, setCheckedProducts] = useState([]);
    let editid = edit?.id;
    const removeModal = () => {
        setShow(false)
        sessionStorage.removeItem("isEdit")
        setIsEdit({ is: false, id: null })
        sessionStorage.removeItem("isDuplicate")
        setIsDuplicate({ is: false, id: null })
        history.push(basePath + "/" + from)
    };
    const [loading, setloading] = useState(true);
    const handleSubmit = () => {
        const apiName = edit?.is == true ? "update" : "create"
        sessionStorage.removeItem("isEdit")
        showLoader();
        setError([])
        var form_data = new FormData();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        for (const key in codesModalReducer) {
            if (Object.hasOwnProperty.call(codesModalReducer, key)) {
                if(codesModalReducer[key] != null){
                    if (key === "products" && Array.isArray(codesModalReducer[key])) {
                        codesModalReducer[key]?.forEach(innerArray => {
                            if (Array.isArray(innerArray)) {
                                innerArray?.forEach(id => {
                                    form_data.append("products[]", id);
                                });
                            } else {
                                form_data.append("products[]", innerArray);
                            }
                        });
                    }  else {
                        form_data.append(key, codesModalReducer[key]);
                    }
                }
            }
        };
        form_data.append('project', slug);
        if (apiName == "update"){
            form_data.append('code_id', edit?.id);
        }
        const setOptions = {
            method: "POST",
            body: form_data,
            headers: myHeaders,
          };
        const api = apiUrl + "/codes/" + apiName;
          fetch(api, setOptions)
            .then(async (res) => {
              const response = await res?.json();
              if (response?.status_code == 200) {
                history.push(basePath + "/codes");
                setShow(false)
                callAllCodes();
                hideLoader();
                toast.success(`Code ${apiName == "update" ? 'Updated' : 'Created'} Successfully `)
              } else {
                setError(Object.values(response?.errors));
                if (Object.keys(response?.errors).length > 0)
                  $(".modal-body").scrollTop(0);
                hideLoader();
              }
            })
            .catch(() => {
              hideLoader();
            });
    };

    useEffect(()=>{
        if(isEligible){
            const checkedProductsData = codesModalReducer?.products.map(item => {
                const matchedProduct = allProducts.find(product => product.id === item);
                return {
                    id: matchedProduct?.id,
                    name: matchedProduct?.name
                };
            });
            setCheckedProducts(checkedProductsData);
        }
    },[slug])
    const handleChange = (e) => {
        dispatch({ type: "SET_CODE_MODAL", payload: { ...codesModalReducer, [e.target.name]: e.target.value } })
    };
    const handleProductChange = (e, product) => {
        const isChecked = e.target.checked;
    let updatedProducts;

    if (isChecked) {
        updatedProducts = [...checkedProducts, product];
    } else {
        updatedProducts = checkedProducts.filter(p => p.id !== product.id);
    }

    setCheckedProducts(updatedProducts);

    dispatch({
        type: "SET_CODE_MODAL",
        payload: {
            ...codesModalReducer,
            products: [updatedProducts.map(p => p.id)]
        }
    });
    }
    return (
        <>
            <Modal id="newNewsletterModal" show={show} onHide={removeModal} placement="end" style={{ width: "auto", margin: 0 }} className="right marketing-section-modal" >
                <div class="modal-dialog modal-dialog-centered" role="document" style={{ height: "100%", margin: 0 }}>
                    <div class="modal-content p-0 zaap-border-straight" style={{ height: "100%" }}>

                        <div class="modal-header send-newsletter-header" style={{ border: "none", }}>
                            <h4 class="modal-title create-biolink-heading posthead">{edit?.is ? `Edit` : `Create`} Discount</h4>

                            <button type="button" class="close-new" onClick={removeModal}>
                                <span aria-hidden="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
                                        <path d="M8 1.5L1 8.5M1 1.5L8 8.5" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>Exit
                                </span>
                            </button>
                        </div>

                        <div class="modal-body body-u">

                            <ErrorMessage error={error} />
                            {/* <div class="form-group" style={{ marginTop:"8px"}}>
                                <div class="up-in-toggle bg-f1f1f1 w-50 btn-semi-rounded" style={{ padding: "5px 3px" }}>
                                    <input type="radio" id="text_align_left" name="is_custom" defaultChecked={!codesModalReducer?.is_custom ? true : false} style={{ display: "none" }} value={0} />
                                    <label for="text_align_left" class="btn-semi-rounded toggletext newsletter-btn-semi-rounded " > Random</label>
                                    <input type="radio" id="text_align_right" name="is_custom" defaultChecked={codesModalReducer?.is_custom ? true : false} style={{ display: "none" }} value={1} />
                                    <label for="text_align_right" class="btn-semi-rounded toggletext newsletter-btn-semi-rounded "  > Custom</label>
                                </div>
                            </div> */}
                            <div class="form-group">
                                <label class="text-gray font-weight-500"><small>Discount Code</small></label>
                                <input type="text" style={{ marginTop:"6px"}} value={codesModalReducer?.code} onChange={handleChange} class="form-control zaap-form-control new-title-inp" name="code" required="required"  placeholder="Eg: BlackFriday10" />
                            </div>

                            <div class="form-group mt-5 mb-4">
                                <div class="up-in-toggle bg-f1f1f1 w-50 btn-semi-rounded" style={{ padding: "5px 3px" }}>
                                    <input type="radio" id="code_type_left" onChange={handleChange} name="code_type" defaultChecked={codesModalReducer?.code_type == "fixed" ? true : false} style={{ display: "none" }} value={"fixed"} />
                                    <label for="code_type_left" class="btn-semi-rounded toggletext newsletter-btn-semi-rounded " >Fixed</label>
                                    <input type="radio" id="code_type_right" onChange={handleChange} name="code_type" defaultChecked={codesModalReducer?.code_type == "percentage" ? true : false} style={{ display: "none" }} value={"percentage"} />
                                    <label for="code_type_right" class="btn-semi-rounded toggletext newsletter-btn-semi-rounded "  > Percentage</label>
                                </div>
                            </div>

                            <div class="form-group"style={{ marginTop:"24px"}} >
                                <label class="text-gray font-weight-500"><small>Amount</small></label>
                                <input type="number" style={{ marginTop:"6px"}} onChange={handleChange} class="form-control zaap-form-control new-title-inp" name="amount" value={codesModalReducer?.amount} required="required"  placeholder="Eg: 10%" />
                            </div>
                            <div class="form-group"style={{ marginTop:"13px"}} >
                                <label class="text-gray font-weight-500"><small>Number of Uses</small></label>
                                <input type="number" style={{ marginTop:"6px"}} onChange={handleChange} class="form-control zaap-form-control new-title-inp" name="no_of_uses" value={codesModalReducer?.no_of_uses} required="required"  placeholder="Eg: 10" />
                            </div>
                            <div class="form-group"style={{ marginTop:"13px"}} >
                                <label class="text-gray font-weight-500"><small>Date Range</small></label>
                                    <input type="date" style={{ marginTop:"6px"}} onChange={handleChange} class="form-control zaap-form-control new-title-inp" name="date_range" value={codesModalReducer?.date_range} required="required"  placeholder="Eg: 10%" />
                                    <input type="date" style={{ marginTop:"6px"}} onChange={handleChange} class="form-control zaap-form-control new-title-inp" name="date_range_end" value={codesModalReducer?.date_range_end} required="required"  placeholder="Eg: 10%" />
                            </div>
                            <div class="form-group"style={{ marginTop:"13px"}} >
                                <label class="text-gray font-weight-500"><small>Products</small></label>
                                <div onClick={(e) => e.stopPropagation()} class="align-items-center form-control zaap-form-control new-title-inp add-products-dropdown dropdown-toggle noselect d-md-flex d-none" id="add-products-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                Select Products:
                                {checkedProducts.map((product) => (
                                    <span className="selected-products" key={product?.id}>{product?.name}</span>
                                ))}
                                </div>
                                <ul className="code-products-dropdown dropdown-menu" aria-labelledby="add-products-dropdown">
                                    {allProducts?.map((value, index) => {
                                        const isChecked = checkedProducts?.some(val =>  val?.name === value?.name); 
                                        return (
                                            <li key={index} className="dropdown-item products-option"onClick={() => {
                                                const checkbox = document.getElementById(`product-checkbox-${value?.id}-${index}`);
                                                if (checkbox) {
                                                    checkbox.click();
                                                }}}>
                                            <input
                                                data-id={value?.id}
                                                onChange={(e)=>handleProductChange(e,value)}
                                                className="product-checked "
                                                type="checkbox"
                                                id={`product-checkbox-${value?.id}-${index}`}
                                                style={{ display: 'none' }}
                                                checked={isChecked}
                                            />
                                            <label onClick={(e) => e.stopPropagation()} style={{ backgroundColor: value?.color }} className="small-box" htmlFor={`product-checkbox-${value?.id}-${index}`}>
                                            </label>
                                            <span  className={`cursor-pointer  ${isChecked? 'checked-product' : ''}`}>{value?.name}</span>
                                        </li>)
                                    })}
                                </ul>
                            </div>




                        </div>

                        <div class="send-newsletter-footer">
                            <button type="button" class="btn btn-primary btn-semi-rounded d-flex align-items-center send-newsletter-btn justify-content-center py-2 px-3" onClick={handleSubmit}>
                                <b class="me-2 newbtn-u2">Publish</b>
                                <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                    <img src={returnIcon} alt="" />
                                </small>
                            </button>
                        </div>

                    </div>

                </div>
            </Modal>
            {showModalasd && <Teammoadal showModal={showModalasd} heading={'Upgrade to access Codes.'} text={' This is a Pro Feature<br /> Upgrade to Zaap Pro to unlock this feature.'} isUltra={true} />}
        </>
    )
}
export default CodesModal
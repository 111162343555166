
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap"
import embed_svg from "../../../../assets/img/spotify-sync.svg"


const CreateSpotifySyncBlockModal = ({ show, handleHide, returnIcon, handleSubmit, embedBlockModal, setembedBlockModal }) => {


    const [value, setValue] = useState({
        url: null,
    });
    
    const handleChange = (e) => setValue({ ...value, [e.target.name]: e.target.value })

    useEffect(()=>{
        setValue({...embedBlockModal, url: embedBlockModal?.details?.url})
    },[])

    return (
        <Modal centered show={show} onHide={!embedBlockModal?.show ? handleHide : () => {
            setembedBlockModal({
                ...embedBlockModal,
                show: !embedBlockModal?.show,
            })
        }} id="createTextBlockModal">
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header gap-2 justify-content-start">
                    <img src={embed_svg} width="30px" alt="" />
                    <span class="mt-2 mb-1 font-weight-500 fs-14px">Spotify Sync</span>
                </div>
                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e, {
                                url: value?.url,
                                name: value?.name,
                            }, embedBlockModal?.show ?  embedBlockModal?.id : "spotify_sync", true)
                        }}>
                            <div class="notification-container"></div>
                            <div class="form-group">
                                <label><small>Title</small></label>
                                <input type="text" value={value?.name} class={`zaapGlobal-input`} onChange={handleChange} name="name" required="required" placeholder="My Playlist" />
                            </div>
                            <div class="form-group mt-3">
                                <label><small>Enter any Spotify Link</small></label>
                                <input type="url" value={value?.url} class={`zaapGlobal-input`} onChange={handleChange} name="url" required="required" placeholder="https://open.spotify.com/embed" />
                            </div>
                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={!embedBlockModal?.show ? handleHide : () => {
                                    setembedBlockModal({
                                        ...embedBlockModal,
                                        show: !embedBlockModal?.show,
                                    })
                                }} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <span class="me-2">Cancel</span>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button autoFocus type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <span class="me-2">Save Changes</span>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Modal>
    )
}

export default CreateSpotifySyncBlockModal
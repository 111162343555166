import NewsletterModal from "../components/newsletter/modal";
import BlockEditor from "../components/page-editor/block-editor";
import SubModals from "../components/page-editor/block-editor/sub-modals";
import CustomizePage, { CustomizePageModal } from "../components/page-editor/customize-page";
import ProductModals from "../components/products/product_modals";
import { AcceptInvite } from "../pages/accept-invite";
import Accounts from "../pages/accounts";
import Analytics from "../pages/analytics";
import Audience from "../pages/audience";
import CallbackLogin from "../pages/callback-login";
import Dashboard from "../pages/dashboard";
import Logout from "../pages/logout";
import ManageBillings from "../pages/manage-billings";
import NewsLetter from "../pages/newsletter";
import Orders from "../pages/orders";
import PageEditor from "../pages/page-editor";
import PaypalConnect from "../pages/paypal-connect.jsx";
import Products from "../pages/products";
import StripeCheckout from "../pages/stripe-checkout";
import StripeConnectReauth from "../pages/stripe-connect-reauth";
import StripeConnectReturn from "../pages/stripe-connect-return";
import Teammembers from "../pages/team-members";
import TikTokCalBack from "../pages/tiktok-callback";
import TwitterCallback from "../pages/twitter-callback";
import Walkthrough from "../pages/walkthrough";
import Createproduct from "../components/products/create-product";
import ConnectCalendar from "../pages/connect-calendar";
import Settings from "../components/settings/settings";
import AccountSettings from "../components/settings/account-settings";
import Post from "../pages/posts.jsx";
import DiscountCode from "../pages/discount-code.jsx";
import CodesModal from "../components/codes/codes-modal.jsx";
import InstagramCallBack from "../pages/instagram-callback.jsx";
import ProductStep1 from "../components/products/product_step_1.jsx";
import ProductMain from "../components/products/product-main.jsx";
import SectionPage from "../components/products/product-settings/product-tabs/sections.jsx";
import ThreeBoxPage from "../components/products/product-settings/product-tabs/product-section/three-box-page.jsx";
import ZoomCallback from "../pages/zoom-callback.jsx";
import SpotifyCallBack from "../pages/spotify-callback.jsx";
export let basePath = "/project/:id"
export const changeBasePath = (slug)=> basePath = `/project/${slug}`

const routes = [
    {
        exact: false,
        path: "/dashboard",
        component: Dashboard,
        haveSubPath: false
    },
    {
        exact: false,
        path: "/accept-invite",
        component: AcceptInvite,
    },
    {
        exact: false,
        path: basePath + "/page-editor",
        component: PageEditor,
        haveSubPath: true
    },
    // {
    //     exact: false,
    //     path: basePath + "/settings/team-members",
    //     component: Teammembers,
    //     haveSubPath: true
    // },
    {
        exact: false,
        path: basePath + "/settings",
        component: Settings,
        haveSubPath: false
    },
    {
        exact: false,
        path:"/account-settings",
        component: AccountSettings,
    },
    // {
    //     exact: false,
    //     path: basePath + "/settings/connect-calendar",
    //     component: ConnectCalendar,
    //     haveSubPath: true
    // },
    {
        exact: false,
        path: basePath + "/newsletter",
        component: NewsLetter,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/posts",
        component: Post,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/codes",
        component: DiscountCode,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/audience",
        component: Audience,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/products",
        component: Products,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/orders",
        component: Orders,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/analytics",
        component: Analytics,
        haveSubPath: false
    },
    {
        exact: false,
        path: "/logout",
        component: Logout,
        haveSubPath: false
    },
    {
        exact: false,
        path: "/walkthrough",
        component: Walkthrough,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/createProducts",
        component: Createproduct,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/editProduct",
        component: ProductMain,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/editProduct/createThreeBoxSection",
        component: ThreeBoxPage,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/newsletter/sendNewsletter",
        component: NewsletterModal,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/newsletter/sendCode",
        component: CodesModal,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/post/sendPost",
        component: NewsletterModal,
        haveSubPath: false
    },
    {
        exact: false,
        path: basePath + "/page-editor/:blockEditorId",
        component: BlockEditor,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/:blockEditorId/:subPath",
        component: SubModals,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/newBlockModal",
        component: BlockEditor,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/newBlockModal/createFolderModal",
        component: SubModals,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/newBlockModal/createLinkModal",
        component: SubModals,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/newBlockModal/createEmailWidgetModal",
        component: SubModals,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/newBlockModal/createProductWidgetModal",
        component: SubModals,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/newBlockModal/createYoutubeWidgetModal",
        component: SubModals,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/newBlockModal/createTiktokWidgetModal",
        component: SubModals,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/newBlockModal/createInstagramWidgetModal",
        component: SubModals,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/newBlockModal/createTwitterWidgetModal",
        component: SubModals,
        haveSubPath: true
    },

    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal",
        component: CustomizePageModal,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customDomainModal",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageProfile",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageTheme",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageSocials",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageFonts",
        component: CustomizePage,
        haveSubPath: true
    },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/customizePageModal/customizePageDomains",
    //     component: CustomizePage,
    //     haveSubPath: true
    // },
    // {
    //     exact: false,
    //     path: basePath + "/page-editor/customizePageModal/customizePageDomains/customDomainModal",
    //     component: CustomizePage,
    //     haveSubPath: true
    // },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageSEO",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageIntegrations",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: basePath + "/page-editor/customizePageModal/customizePageIntegrations/googleAnalytics",
        component: CustomizePage,
        haveSubPath: true
    },
    {
        exact: false,
        path: "/twitter-callback",
        component: TwitterCallback,
        haveSubPath: true
    },
    {
        exact: false,
        path: "/tiktok-callback",
        component: TikTokCalBack,
        haveSubPath: true
    },
    {
        exact: false,
        path: "/instagram-callback",
        component: InstagramCallBack,
        haveSubPath: true
    },
    {
        exact: false,
        path: "/zoom-callback",
        component: ZoomCallback,
        haveSubPath: true
    },
    {
        exact: false,
        path: "/stripe-checkout",
        component: StripeCheckout,
    },
    {
        exact: false,
        path: "/stripe-connect-reauth",
        component: StripeConnectReauth,
    },
    {
        exact: false,
        path: "/stripe-connect-return",
        component: StripeConnectReturn,
    },
    {
        exact: false,
        path: "/paypal-connect",
        component: PaypalConnect,
    },
    {
        exact: false,
        path: "/callback-login",
        component: CallbackLogin,
    },
    {
        exact: false,
        path:"/manage-billings",
        component: ManageBillings,
    },
    {
        exact: false,
        path: "/spotify-callback",
        component: SpotifyCallBack,
        haveSubPath: true
    },
    
]
export default routes
import { Modal, Alert } from "react-bootstrap";
import { toast } from 'react-toastify';
import { useState, useEffect } from "react"
import { hideLoader, showLoader } from "../../../components/loader"
import ModalErrors from "../../errors/modal-errors";
import { apiUrl } from "../../../utils/url";
import returnIcon from "../../../assets/img/return.svg"
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const DashboardProjectsModal = ({ showModal, handleCloseModal, getProjects }) => {
    console.log("showModal",showModal)
    const history = useHistory()
    const dispatch = useDispatch()
    const [value, setValue] = useState(null);
    const [error, setError] = useState([])
    const handleChange = (e) => setValue(e?.target?.value);

    const handleSubmit = (e) => {
        e.preventDefault();
        showLoader();
        setError([]);
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData();
        if(showModal?.duplicate){
            formdata.append("project",showModal?.slug)
        }
        formdata.append("link", value)
        let errorList = [];
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(`${apiUrl}/project/${!showModal?.duplicate ? "create" : "duplicate"}` , requestOptions)
            .then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    localStorage.setItem("slug", response?.data?.slug)
                    dispatch({ type: "SET_SLUG", payload: { slug: response?.data?.slug, basePath: "/project/" + response?.data?.slug, newChange: false } })
                    history.push({
                        pathname:`/dashboard`,
                        state: { newProjectcreated:true }
                    });

                    if (response?.status_message) toast.success(response?.status_message);
                    handleCloseModal();
                    setValue(null)
                    setError([])
                    getProjects();
                }
                else {
                    if (response?.errors?.link)
                        response?.errors?.link?.map((val) => toast.error(val))
                    // if (response?.status_message) toast.error(response?.status_message);
                    // setError(errorList)
                    setValue(null)
                }
            }).catch((err) => hideLoader())
    }

    return (
        <Modal centered show={showModal?.show} onHide={()=>{
            setError([])
            setValue(null)
            handleCloseModal()
        }} id="createFolderModal"  >
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content modal-semi-curve">

                    <div className="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={handleSubmit}>
                            <div className="notification-container"></div>


                            <div className="form-group">
                                {/* <img src={folderIcon} width="30px" alt="" /> */}
                                <h4 className="mt-2 mb-1">{!showModal?.duplicate ? `Create a New Project.` : `Duplicate Project`}</h4>
                                <p className="text-gray font-weight-500">
                                    {/* Organise your related links into folders. */}
                                </p>
                            </div>
                            <div class="form-group">
                                <label htmlFor=""><strong>Your Link</strong></label>
                                <div class='input overflow-hidden'>
                                    <span id="input-prefix">
                                        zaap.bio/
                                    </span>
                                    <input type="text" name="link"
                                        value={value}
                                        onChange={handleChange}
                                        placeholder="your link" required="required" />
                                </div>
                                {error?.map((val, index) => <ModalErrors text={val} />)}
                            </div>

                            <div className="text-center mt-4 d-flex">
                                <button type="button"
                                    onClick={()=>{
                                        setError([])
                                        setValue(null)
                                        handleCloseModal()
                                    }}
                                    className="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <span className="me-2">Cancel</span>
                                    <small className="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit"
                                    className="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <span className="me-2">{!showModal?.duplicate ? `Create ` : `Duplicate `}Project</span>
                                    <small className="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default DashboardProjectsModal
const INIT_STATE = false;
const projectDisabledReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_PROJECT_DISABLED':
            return action.payload 
        case 'RESET_PROJECT_DISABLED':
            return INIT_STATE
        default:
            return state
    }

}
export default projectDisabledReducer
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, useHistory, useParams } from "react-router-dom";
import Loader from "../components/loader";
import AuthLayout from "../components/routes/auth-layout";
import DashboardLayout from "../components/routes/dashboard-layout"
import CallbackLogin from "../pages/callback-login";
import Logout from "../pages/logout";
import NotFound from "../pages/not-found";
import StripeCheckout from "../pages/stripe-checkout";
import { AcceptInvite } from "../pages/accept-invite";
import Walkthrough from "../pages/walkthrough";
import UnPaidPage from "../components/routes/unpaid-page";

const Routes = () => {
    const state = useSelector(state => state.Login);
    const [screen, setScreen] = useState(null);
    const history = useHistory();
    const { id, pagename } = useParams();
    const walkthroughState = useSelector(state => state.walkthroughSteps);

    useEffect(() => {
        localStorage.removeItem("sendRequest")
        try {
            if (!state.user) {
                if (localStorage.getItem("user") && window.location.pathname == "/login" && localStorage.walkthrough == 1) {

                    window.location.pathname = "/dashboard"
                }
                else if (localStorage.getItem("user") && localStorage.walkthrough == 0) {
                    history.replace("/walkthrough?step=" + localStorage.currentStep);
                }
                else {
                    // setScreen(<DashboardLayout />)
                    setScreen(<UnPaidPage /> )
                    }
            }
        }
        catch { }
    }, []);
    return (
        <>
            {
                localStorage.getItem("user") ?
                    <>
                        {(localStorage.getItem("walkthrough") == 0 || walkthroughState.walkthrough == 0) ?
                            <>
                                <Route path={"/walkthrough"} exact component={() => <Walkthrough />} />
                                <Route path={"/logout"} component={() => <Logout />} />
                                <Route path="/accept-invite" component={() => <AcceptInvite />} />
                                <Route path="/stripe-checkout" component={() => <StripeCheckout />} />
                                <Route path={"/callback-login"} component={() => <CallbackLogin />} />
                                {/* <Route path={"*"} exact component={() => {
                                    const history = useHistory();
                                    useEffect(() => {

                                        history.replace("/walkthrough?step=" + localStorage.currentStep);

                                    }, [])
                                    return (<div class="full-page-loader d-flex">
                                        <div class="lds-ripple"><div></div><div></div></div>
                                    </div>)
                                }} /> */}
                            </>
                            /* (state.user || localStorage.getItem("user")) ?  */
                            : screen
                        }
                    </>
                    : <><AuthLayout /></>


            }
            <Route path="/_404" component={NotFound} />
            <Loader />
        </>
    )
}
export default Routes